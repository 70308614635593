<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-26 16:25:23
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 14:08:22
-->
<template>
  <el-card class="box-card" shadow="never">
    <div slot="header" class="clearfix">
      <span class="time">{{detail.modifyDate}}</span>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: './messageList' }">消息中心</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: './messageList',query:{tab:$route.query.tab} }"
        >{{messageType}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{detail.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <p class="title" :title="detail.title">{{detail.title}}</p>
    <div v-html="detail.content" class="content"></div>
  </el-card>
</template>
<script>
import api from "@/api/list";
export default {
  data() {
    return {
      detail: "",
      messageType: "",
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.articleList({ id: this.$route.query.id }).then((res) => {
        this.detail = res.data.records[0];
      });

      if (this.$route.query.tab) {
        if (this.$route.query.tab == "first") {
          this.messageType = "常见问题";
        } else if (this.$route.query.tab == "second") {
          this.messageType = "员工须知";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin: 6px 0 20px;
  line-height: 30px;
}

.time {
  color: #999;
  font-size: 14px;
  float: right;
}

.box-card {
  /deep/ .el-card__body {
    padding: 10px 10px 70px 10px;
  }
  /deep/ .el-breadcrumb__inner {
    display: inline-block;
    vertical-align: middle;
    max-width: 10em;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  font-size: 14px;
  line-height: 1.5;
  height: 562px;
  width: 100%;
  overflow-y: scroll;
  /deep/ img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 10px 0;
  }

  /deep/ pre {
    width: 100%;
    height: 200px;
    overflow: auto;
  }
}
</style>
